import ajax from '@/utils/ajax.js'

// 获取用户银行卡
 export function getCardList(params) {
  return ajax.post('/api/getCardList',params)
}

// 支持的银行
export function listSupportBank(params) {
  return ajax.post('/api/listSupportBank',params)
}

// 绑定银行卡
export function confirmBindCard(params) {
  return ajax.post('/api/confirmBindCard',params)
}

// 申请绑卡发送短信验证码
export function bindCardSmsCode(params) {
  return ajax.post('/api/bindCardSmsCode',params)
}

// 检测用户绑卡信息
export function checkUserBindCard(params) {
  return ajax.post('/api/checkUserBindCard',params)
}

