<template>
  <div class="stages-info">
    <van-dialog
      v-model="showPayment"
      class="payment-dialog"
      @cancel="cancel"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="title">账单明细</div>
      <div class="payment-content">
        <div class="content-item">
          <div class="item-label">分期金额：</div>
          <div class="item-value">{{stagesInfoList.repayment_principal}}</div>
        </div>
        <div class="content-item" v-if="Number(stagesInfoList.repayment_interest_amount) > 0">
          <div class="item-label">管理费：</div>
          <div class="item-value">{{stagesInfoList.repayment_interest_amount}}</div>
        </div>
        <div class="content-item" v-if="Number(stagesInfoList.overdue_administrative_fee) > 0">
          <div class="item-label">逾期手续费：</div>
          <div class="item-value">{{stagesInfoList.overdue_administrative_fee}}</div>
        </div>
        <div class="content-item amount">
          <div class="item-label">待付总额：</div>
          <div class="item-value">{{stagesInfoList.repayment_amount}}</div>
        </div>
      </div>
      <div class="dialog-button">
        <div class="button-confim" @click="cancel">我知道了</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { billStageDetail } from "@/api/bill.js";
export default {
  data() {
    return {
      showPayment: false,
      flag:false,
      stagesInfoList:{}
    };
  },

  mounted() {},

  methods: {
    async isShow(item) {
      this.showPayment = true;
      this.getBillStageDetail(item.bill_stage_id)
      
    },
    async getBillStageDetail(bill_stage_id){
      const parmes = {
        billStageId:bill_stage_id
      }
      const {code,data} = await billStageDetail(parmes)
      var overdue_administrative_fee = Number(data.overdue_late_fee) + Number(data.overdue_fine)

      if(code == 200){
        this.stagesInfoList = {
          ...data,
          overdue_administrative_fee:overdue_administrative_fee.toFixed(2)
        };
      }
    },
    cancel() {
      this.showPayment = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.stages-info {
  color: #333;
  font-size: 28px;
  .title{
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .payment-content{
    font-size: 28px;
    border-top: 1px solid #D9D9D9;
    padding-top: 10px;
    margin: 0px 40px;
    .content-item{
      display: flex;
      margin: 20px 0px;
      .item-label{
        flex: 1;
      }
      .item-value{
        text-align: right;
      }
    }
    .amount{
      color: #333;
      font-weight: 600;
    }
  }
  .dialog-button {
    display: flex;
    align-items: center;
    padding: 30px 40px 40px;
    .button-cancel, .button-confim {
      border: 1px solid #1677ff;
      border-radius: 35px;
      flex: 1;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1677ff;
    }
    .button-confim {
      background: #1677ff;
      color: #fff;
    }
    .button-cancel + .button-confim {
      margin-left: 30px;
    }
  }
}
</style>