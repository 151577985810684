import { render, staticRenderFns } from "./stages-info.vue?vue&type=template&id=285c0c7a&scoped=true"
import script from "./stages-info.vue?vue&type=script&lang=js"
export * from "./stages-info.vue?vue&type=script&lang=js"
import style0 from "./stages-info.vue?vue&type=style&index=0&id=285c0c7a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "285c0c7a",
  null
  
)

export default component.exports