import ajax from '@/utils/ajax.js'

// 获取账单分期预览列表
 export function stagesPreview(params) {
  return ajax.post('/api/stagesPreview',params)
}

// 申请账单
 export function applyBill(params) {
  return ajax.post('/api/applyBill',params)
}

// 主动还款
export function advanceRepayment(params) {
  return ajax.post('/api/advanceRepayment',params)
}

// 获取账单卡片列表
export function billCardList(params) {
  return ajax.post('/api/billCardList',params)
}

// 获取用户账单审核信息列表
export function billApproveList(params) {
  return ajax.post('/api/billApproveList',params)
}

// 分期账单列表
export function billStageList(params) {
  return ajax.post('/api/billStageList',params)
}

// 分期账单详情
export function billStageDetail(params) {
  return ajax.post('/api/billStageDetail',params)
}

// 分期账单历史列表
export function billStageRepaymentHistory(params) {
  return ajax.post('/api/billStageRepaymentHistory',params)
}

//全部还款预览
export function allPrepaymentPreview(params) {
  return ajax.post('/api/allPrepaymentPreview',params)
} 

// 主动还款（微信支付）
export function prePaymentByWechat(params) {
  return ajax.post('/api/prePaymentByWechat',params)
} 

// 主动还款（阿里支付）
export function prePaymentByAliPay(params) {
  return ajax.post('/api/prePaymentByAliPay',params)
}

// 部分还款计算
export function calcPartAmount(params) {
  return ajax.post('/api/partPrepaymentPreview', params)
}

export function applyFastBill(params) {
  return ajax.post('api/applyFastBill', params)
}

