<template>
  <div class="all-payment">
    <van-dialog
      v-model="showPayment"
      class="payment-dialog"
      @confirm="confirm"
      @cancel="cancel"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="title">费用明细</div>
      <div class="payment-content">
        <div class="content-item" v-if="isPart">
          <van-field
              label="提前还款"
              placeholder="请输入提前还款金额"
              v-model="partInfo.pay_money"
              input-align="right"
              @input="onEarnInput"
            />
        </div>
        <div class="content-item" v-else>
          <div class="item-label">剩余应还：</div>
          <div class="item-value">{{ allPaymentInfo.principal }}</div>
        </div>
        <div class="content-item" v-if="Number(allPaymentInfo.interest) > 0">
          <div class="item-label">管理费：</div>
          <div class="item-value">{{ allPaymentInfo.interest }}</div>
        </div>
        <div class="content-item" v-if="Number(allPaymentInfo.overdue_administrative_fee) > 0">
          <div class="item-label">逾期手续费：</div>
          <div class="item-value">{{ allPaymentInfo.overdue_administrative_fee }}</div>
        </div>
        <!-- <div class="content-item" v-if="Number(allPaymentInfo.overdueAmount) > 0">
          <div class="item-label">逾期金额：</div>
          <div class="item-value">{{allPaymentInfo.overdueAmount}}</div>
        </div> -->
        <div
          class="content-item"
          v-if="Number(allPaymentInfo.advanceInterest) > 0 || Number(partInfo.single_pay) > 0"
        >
          <div class="item-label">手续费：</div>
          <div class="item-value">{{ isPart ? partInfo.single_pay ? partInfo.single_pay : 0 : allPaymentInfo.advanceInterest }}</div>
        </div>
        <div class="content-item amount">
          <div class="item-label">共计：</div>
          <div class="item-value">{{ isPart ? partInfo.total? partInfo.total: 0 : allPaymentInfo.totalAmount }}</div>
        </div>
      </div>
      <van-radio-group v-model="payType" >
        <div class="pay-type">
          <div
            class="pay-item"
            v-if="bankCard"
            @click="getPayType('card')"
          >
            {{ bankCard.bank_name }}（{{ bankCard.bank_card_no }}）
            <div class="radiopay">
              <van-radio name="card" icon-size="16px"></van-radio>
            </div>
          </div>
          <div v-if="isWeixin && openId != ''" class="pay-item" @click="getPayType('WeChat')">
            微信支付
            <div class="radiopay">
              <van-radio name="WeChat" icon-size="16px"></van-radio>
            </div>
          </div>
          <div class="pay-item" @click="getPayType('aliPay')">
            支付宝支付
            <div class="radiopay">
              <van-radio name="aliPay" icon-size="16px"></van-radio>
            </div>
          </div>
        </div>
      </van-radio-group>
      <div class="dialog-button">
        <div class="button-cancel" @click="cancel">取消</div>
        <div class="button-confim" @click="confirm" v-if="!flag">确定付款</div>
        <div class="button-confim" v-else style="background: #708eb8">
          支付中...
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  allPrepaymentPreview,
  advanceRepayment,
  prePaymentByWechat,
  prePaymentByAliPay,
  calcPartAmount
} from "@/api/bill.js";
import { isWeixin } from "@/utils/index.js";
import { Toast } from "vant";
import { getCardList } from "@/api/bank.js";
export default {
  data() {
    return {
      showPayment: false,
      bill_id: "",
      allPaymentInfo: {},
      flag: false,
      payType: "card",
      isWeixin: isWeixin(),
      openId: "",
      bankCard: null,
      isPart: false,
      partInfo: {
        total: 0,
        single_pay: 0,
        pay_money: ''
      }
    };
  },

  mounted() {
    this.openId = localStorage.getItem("openId");
  },

  methods: {
    async isShow(bill_id) {
      this.showPayment = true;
      this.bill_id = bill_id;
      this.isPart = false
      this.getCard();
      const { code, data } = await allPrepaymentPreview({ billId: bill_id });
      if (code == 200) {
        var overdue_administrative_fee = Number(data.overdue_late_fee) + Number(data.overdue_fine)
        this.allPaymentInfo = {
          ...data,
          overdue_administrative_fee:overdue_administrative_fee.toFixed(2)
        };
      }
    },
    partShow(bill_id) {
      this.showPayment = true;
      this.bill_id = bill_id;
      this.isPart = true
      this.getCard();
    },
    onEarnInput(value) {
      console.log(value)
      if (value == 0) {
        return
      }
      this.calc()
    },
    async calc() {
      const parmes = {
        billId: this.bill_id,
        pay_money: this.partInfo.pay_money,
      };
      const { code, data } = await calcPartAmount(parmes)
      if (code ==  200) {
        this.partInfo.single_pay = data.single_pay
        this.partInfo.total = data.total
      }
    },
    async getCard() {
      const { code, data } = await getCardList();
      if (code == 200) {
        if (data.list.length == 0) {
          if(this.isWeixin){
            this.payType = "WeChat"
          } else {
            this.payType = "aliPay"
          }
        } else {
          data.list.map((item) => {
            if (item.is_default == 1) {
              this.bankCard = {
                bank_name: item.bank_name,
                bank_card_no: item.bank_card_no.substring(
                  item.bank_card_no.length - 4,
                  item.bank_card_no.length
                ),
              };
            }
          });
        }
      }
    },
    getPayType(type) {
      this.payType = type;
    },
    async confirm() {
      if (this.flag) return;
      this.flag = true;
      if (this.payType == "card") {
        if(!this.bankCard){
          Toast("请先绑定银行卡");
          this.flag = false;
        } else {
          this.getCardPay();
        }
      } else if (this.payType == "WeChat") {
        this.getWeChatPay();
      } else if(this.payType == "aliPay"){
        this.getAliPayPay()
      }
    },
    // 支付宝支付
    async getAliPayPay() {
      const that = this;
      const parmes = {
        billId: this.bill_id,
        advanceType: this.isPart ? 2 : 3,
      };
      if (this.isPart) {
        parmes.repaymentAmount = this.partInfo.pay_money
      } 
      const { code, data } = await prePaymentByAliPay(parmes);
      that.flag = false;
      if (code == 200) {
        that.aliPay(data.prepay);
      }
    },
    aliPay(prepay){
      if (prepay) {
        this.$router.push({
          path: "/AlipayPayment",
          query: {
            alipay_url: prepay,
          },
        });
      } else {
        // TODO 错误提示
      }
    },
    // 微信支付
    async getWeChatPay() {
      const that = this;
      const parmes = {
        billId: this.bill_id,
        advanceType: this.isPart ? 2 : 3,
        
        openId: this.openId,
      };
      if (this.isPart) {
        parmes.repaymentAmount = this.partInfo.pay_money
      } 
      const { code, data } = await prePaymentByWechat(parmes);
      that.flag = false;
      if (code == 200) {
        that.wxPay(data.prepay);
      }
    },
    wxPay(prepay) {
      var vm = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            vm.onBridgeReady(prepay),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", vm.onBridgeReady(prepay));
          document.attachEvent(
            "onWeixinJSBridgeReady",
            vm.onBridgeReady(prepay)
          );
        }
      } else {
        vm.onBridgeReady(prepay);
      }
    },
    onBridgeReady(prepay) {
      const that = this;
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: prepay.appId,
          timeStamp: prepay.timeStamp,
          nonceStr: prepay.nonceStr,
          package: prepay.package,
          signType: prepay.signType,
          paySign: prepay.paySign,
        },
        function (res) {
          // console.log('weixinzhifu',res)
          that.flag = false;
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            Toast.clear();
            Toast.success("支付成功");
            that.$parent.initData();
            that.showPayment = false;
            that.flag = false;
          } else {
            Toast("支付失败");
            that.flag = false;
          }
        }
      );
    },
    async getCardPay() {
      const parmes = {
        billId: this.bill_id,
        advanceType: this.isPart ? 2 : 3,
      };
      if (this.isPart) {
        parmes.repaymentAmount = this.partInfo.pay_money
      } 
      const { code } = await advanceRepayment(parmes);
      this.flag = false;
      if (code == 200) {
        Toast("还款成功");
        this.showPayment = false;
        this.flag = false;
        this.$parent.initData();
      } else {
        this.flag = false;
      }
    },
    cancel() {
      this.showPayment = false;
      if (this.isPart) {
        this.partInfo.single_pay = 0
        this.partInfo.total = 0
        this.partInfo.pay_money = ""
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.all-payment {
  color: #333;
  font-size: 28px;
  .title {
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .payment-content {
    font-size: 28px;
    border-top: 1px solid #d9d9d9;
    padding-top: 10px;
    margin: 0px 40px;
    .content-item {
      display: flex;
      margin: 20px 0px;
      .item-label {
        flex: 1;
      }
      .item-value {
        text-align: right;
      }
      &.amount {
        color: #333;
        font-weight: 600;
        align-items: baseline;
        .item-value {
          font-size: 40px;
        }
      }
    }
  }
  .pay-type {
    margin: 20px 40px;
    font-size: 28px;
    .pay-item {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 20px;
      margin: 20px 0px;
      border: 1px solid #1677ff;
      box-shadow: 0px 0px 10px 0px #fff;
      border-radius: 14px;
      .radiopay {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
    }
  }
  .dialog-button {
    display: flex;
    align-items: center;
    padding: 30px 40px 40px;
    .button-cancel,
    .button-confim {
      border: 1px solid #1677ff;
      border-radius: 35px;
      flex: 1;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1677ff;
    }
    .button-confim {
      background: #1677ff;
      color: #fff;
    }
    .button-cancel + .button-confim {
      margin-left: 30px;
    }
  }
}
</style>