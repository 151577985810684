<template>
  <div class="paymentSchedule">
    <div class="go-back">
      <div @click="onClickLeft" style="flex:1;">
        <van-icon name="arrow-left" class="icon-left" />
        <span>付款计划</span>
      </div>
      <div style="font-size:12px;margin-right:10px" @click="toPaymentHistory">
        付款历史
      </div>
    </div>
    <div class="content">
      <div v-if="allStagesList.length == 0  && !flag">
        <van-empty description="暂无待付账单" />
      </div>
      <!-- @click="onPayment(item)" -->
      <div class="each" v-else v-for="item in allStagesList" :key="item.bill_stage_id" >
        <div class="periods">{{item.current_stage}}/{{item.total_stage}}期</div>
        <div class="dot"></div>
        <div class="date">
          <div>应付日期</div>
          <div style="margin-top:5px">{{item.repayment_date}}</div>
        </div>
        <div class="price">
          <span>￥{{item.repayment_amount}}</span>
        </div>
        <div @click="onStagesInfo(item)">
          <van-icon name="info-o" style="margin-left:10px"/>
        </div>
      </div>
    </div>
    <div class="bottom" v-if="allStagesList.length != 0  && !flag">
      <!-- <div class="bottom-buttom" style="background: #1677FF;color:#fff" @click="partPayment">部分先付清</div> -->
      <div class="bottom-buttom" style="background: #1677FF;color:#fff" v-if="this.allStagesList.length != 0" @click="allPayment">一次性付清</div>
      <div class="bottom-buttom" style="background: #708eb8;color:#fff" v-else>一次性付清</div>
    </div>
    <all-payment ref="allPayment"/>
    <stages-info ref="stagesInfo"/>
  </div>
</template>

<script>
import {billStageList} from '@/api/bill.js'
import { Toast } from "vant";
import allPayment from './components/all-payment.vue';
import StagesInfo from './components/stages-info.vue';
export default {
  components: { allPayment, StagesInfo },
  name: 'paymentSchedule',
  data() {
    return {
      allStagesList:[],
      bill_id:'',
      flag:false
    };
  },
  watch:{
  },
  mounted() {
    this.bill_id = this.$route.query.bill_id
    this.initData()
  },

  methods: {
    async initData(){
      this.flag = true
      const parme = {
        billId:this.bill_id
      }
      const {code,data} = await billStageList(parme)
      if(code == 200){
        this.flag = false
        this.allStagesList = data
      }
    },
    onStagesInfo(item){
      this.$refs.stagesInfo.isShow(item)
    },
    toPaymentHistory(){
      this.$router.push({
        path:"/paymentHistory",
        query:{
          bill_id:this.bill_id
        }
      });
    },
    onPayment(item){
      localStorage.setItem('returnOrderPath',"/paymentSchedule")
      this.$router.push({
        path:"/schedulePayment",
        query:{
          bill_stage_id:item.bill_stage_id
        }
      });
    },
    partPayment() {
      // 部分结款
      this.$refs.allPayment.partShow(this.bill_id)
    },
    allPayment(){
      var len = this.allStagesList.length
      if(len != 0){
        this.$refs.allPayment.isShow(this.bill_id)
      } else {
        Toast('当前账单已全部结清')
      }
    },
    onClickLeft(){
      let returnPath = localStorage.getItem('returnPath')
      if(returnPath == "/payment"){
        this.$router.push("/payment");
      } else if(returnPath == "/myOrder") {
        this.$router.push("/myOrder");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.paymentSchedule{
  height: 100%;
  width: 100%;
  font-size: 30px;
  color: #333;
  display: flex;
	flex-direction: column;
  overflow: hidden;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    height: 100px;
  }
  .content{
    flex: 1;
    overflow: auto;
    padding: 30px;
    .each{
      margin: 30px 0px;
      display: flex;
      align-items: center;
      .periods{
        width: 120px;
      }
      .dot{
        margin-right:30px;
        width:10px;
        height:10px;
        border-radius:50%;
        background-color:#BFBFBF;
      }
      .date{
        flex: 1;
      }
      .price{
        font-size: 36px;
        font-weight: 600;
      }
    }
  }
  .bottom{
    border-top: 1px solid #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    .bottom-buttom{
      flex: 1;
      padding: 20px 70px;
      display: flex;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      margin: 0px 20px;
      color: #1677FF;
      font-size: 30px;
    }
  }
}
</style>